import ReactDom from 'react-dom';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import DetectBrowser from 'components/error/DetectBrowser';
import ErrorBoundary from 'components/error/ErrorBoundary/ErrorBoundary';
import { GenericError } from 'components/error/ErrorAlert/ErrorAlert';
import LoadingSpinner from 'components/data/LoadingSpinner/LoadingSpinner';
import AuthProvider from 'providers/AuthProvider';
import { isProductionBuild } from 'globalUtils';

import './custom.scss';

// The purpose of dynamically importing the App component here is to reduce
// the size of the initial bundle. Doing this results in a much faster
// first contentful paint.
const App = lazy(() => import('./App'));

ReactDom.render(
  <React.StrictMode>
    <DetectBrowser>
      <ErrorBoundary fallback={<GenericError isFullScreen />}>
        <BrowserRouter>
          <AuthProvider>
            <Suspense fallback={<LoadingSpinner isFullScreen />}>
              <App />
            </Suspense>
          </AuthProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </DetectBrowser>
  </React.StrictMode>,
  document.getElementById('root')
);

if (isProductionBuild) {
  const stopSign = String.fromCodePoint(0x1f6d1);
  // eslint-disable-next-line
  console.log(
    `%c${stopSign}%cStop!`,
    'font-size: 3rem; margin-right: 0.5rem;',
    'font-size: 3rem; font-weight: bold; color: red;'
  );
  // eslint-disable-next-line
  console.log(
    '%cThis is a browser feature intended for developers. If someone told you to copy-paste something here, it is a scam and could compromise your account. Please forward any instructions you receive to: support@rsilogistics.com',
    'font-size: 1.5rem;'
  );
}
