import { Nullish } from './typeUtils';

export const usCaPattern = '+1 (###) ###-####';

const mxCountryCodePrefix = '+52';
// https://en.wikipedia.org/wiki/Area_codes_in_Mexico_by_code_(0-99)
// No three digit area codes begin with a matching two digit area code
const mx2DigitAreaCodes = ['33', '55', '56', '81'];

// This default pattern needs to match the longest possible MX number, which is
// a cell phone number (11 digits). Whenever the user pastes a number into a blank input,
// it will be formatted with this pattern. If this pattern only had 10 hashes, only
// ten numbers would be allowed, so the last digit of a cell phone number would be
// wrongly deleted.
export const mxDefaultPattern = `${mxCountryCodePrefix} # (###) ###-##-##`;

/**
 *
 * @param value The MX phone number to build a pattern for. The phone number must not have
 * its country code attached.
 *
 * @returns The pattern to be used for formatting the phone number.
 */
export const mxPatternBuilder = (value: Nullish<string>) => {
  // If there is no value, return this pattern as a default. Not providing
  // a default pattern causes issues with caret positioning when typing a
  // number into the input.
  if (!value) return mxDefaultPattern;

  // A 1 after the country code indicates a cell phone number.
  const isCellPhone = value.startsWith('1');
  const areaCodeStartIndex = isCellPhone ? 1 : 0;
  const is2DigitAreaCode = mx2DigitAreaCodes.includes(
    value.slice(areaCodeStartIndex, areaCodeStartIndex + 2)
  );
  const prefix = `${mxCountryCodePrefix} ${isCellPhone ? '# ' : ''}`;

  if (is2DigitAreaCode) {
    return `${prefix}(##) ####-####`;
  }
  return `${prefix}(###) ###-##-##`;
};

export const phoneNumberCountries = ['US/CA', 'MX'] as const;
export type Country = (typeof phoneNumberCountries)[number];

/**
 *
 * @param phoneNumber The phone number to retrieve the pattern of. The phone number
 * must not have its country code attached.
 *
 * @param selectedOption The country of the phone number.
 *
 * @returns The pattern to be used for formatting the phone number.
 */
export const getPhoneNumberPattern = (
  phoneNumber: string | null,
  countryName: Country
) => (countryName === 'US/CA' ? usCaPattern : mxPatternBuilder(phoneNumber));

/**
 *
 * @param phoneNumber The phone number to get the country of. The phone number must have
 * no formatting and if it's an MX number, it must have its country code attached.
 *
 * @returns The country of the phone number.
 */
export function getPhoneNumberCountry(phoneNumber: string): Country {
  // MX phone numbers should always have their country code (52) included when they are stored
  // in the database, which will result in most MX phone numbers being length 12. The exception
  // is MX cell phone numbers, which have an extra 1 immediately after the country code, resulting
  // in a length of 13. US/CA numbers are stored without their country codes, and should have a
  // length of 10.
  return phoneNumber.length >= 12 ? 'MX' : 'US/CA';
}

/**
 *
 * @param phoneNumber The phone number to format. If it's an MX number, it must
 * have its country code attached. This function will also handle phone numbers
 * that are already formatted.
 *
 * @returns The formatted phone number.
 */
export function getFormattedPhoneNumber(phoneNumber: Nullish<string>) {
  if (!phoneNumber) return null;

  const strippedPhoneNumber = stripPhoneNumberFormatting(phoneNumber);

  const phoneNumberCountry = getPhoneNumberCountry(strippedPhoneNumber);

  const phoneNumberWithoutCountryCode =
    phoneNumberCountry === 'MX'
      ? strippedPhoneNumber.substring(2)
      : strippedPhoneNumber;

  const phoneNumberPattern = getPhoneNumberPattern(
    phoneNumberWithoutCountryCode,
    phoneNumberCountry
  );

  return formatWithPattern(phoneNumberWithoutCountryCode, phoneNumberPattern);
}

/**
 * This function was taken directly from react-number-format's source code,
 * with minor modifications. The purpose of this function is to allow us to
 * format phone numbers using the same patterns that we pass as props to NumberFormat, but
 * without being forced to use the NumberFormat React component.
 * https://github.com/s-yadav/react-number-format/blob/960ad2bcec58a812d5e0c1abd84c9a3e9771bc83/src/number_format.js#L457
 *
 *
 * @param numStr The phone number string to be formatted.
 * @param pattern The pattern to follow when formatting.
 * @returns The phone number, formatted according to the pattern.
 */
export function formatWithPattern(numStr: string, pattern: string) {
  let hashCount = 0;
  const formattedNumberAry = pattern.split('');
  for (let i = 0, ln = pattern.length; i < ln; i += 1) {
    if (pattern[i] === '#') {
      formattedNumberAry[i] = numStr[hashCount];
      hashCount += 1;
    }
  }
  return formattedNumberAry.join('');
}

/**
 *
 * @param phoneNumber The phone number to strip.
 *
 * @returns The phone number without any formatting. Commas, parentheses, and any
 * empty spaces will be removed and replaced with an empty string. For US/CA numbers,
 * the entire country code (+1) will be removed. For MX numbers, only the '+' will
 * be removed, because we still need the 52 to know that it's an MX number.
 */
export function stripPhoneNumberFormatting(phoneNumber: string) {
  return phoneNumber.startsWith('+1')
    ? phoneNumber.replace(/^\+\d*|[-()" "]/g, '')
    : phoneNumber.replace(/^\+|[-()" "]/g, '');
}
