import dayjs from 'dayjs';

import {
  parsingValues,
  parseIsoToDateTimeValues,
  stripLeadingZeroes,
  inputValidationRules,
  validateDay,
  DateSegment,
  parseIsoToDateTimeValuesWithMilitaryHour,
} from 'components/inputs/BaseDateTimePicker/helpers';

import { Nullish } from './typeUtils';

export const dateObjToISO = (dateObj: Date) => {
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');
  const hour = dateObj.getHours().toString().padStart(2, '0');
  const minute = dateObj.getMinutes().toString().padStart(2, '0');
  // Format as ISO8601. Default seconds to 00
  return `${year}-${month}-${day}T${hour}:${minute}:00`;
};

export const getCurrentDateISO = () =>
  dateObjToISO(new Date()).split(parsingValues.dateTime)[0];

/**
 * @param offset UTC offset in minutes
 */
export const getCurrentDateTimeISO = (offset?: number) => {
  const date = new Date();
  if (offset) {
    // get date in January to avoid Daylight Savings
    const dateWithoutDST = new Date(date.getFullYear(), 0, 1);
    date.setMinutes(
      date.getMinutes() + dateWithoutDST.getTimezoneOffset() - offset
    );
  }
  return dateObjToISO(date);
};

export const getDisplayDateString = (
  isoString: Nullish<string>,
  format?: Nullish<string>
) => {
  if (!isoString) return null;
  const { month, day, year } = parseIsoToDateTimeValues(
    isoString,
    'DATE_AND_TIME'
  );
  if (format === 'YYYY') {
    return `${year}`;
  }
  const formattedMonth = stripLeadingZeroes(month);
  const formattedDay = stripLeadingZeroes(day);

  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const getDisplayDateTimeString = (isoString: Nullish<string>) => {
  if (!isoString) return null;
  const { month, day, year, hour, minute, period } = parseIsoToDateTimeValues(
    isoString,
    'DATE_AND_TIME'
  );
  const formattedMonth = stripLeadingZeroes(month);
  const formattedDay = stripLeadingZeroes(day);

  return `${formattedMonth}/${formattedDay}/${year} ${hour}:${minute} ${period}`;
};

export const getDisplayTimeString = (isoString: Nullish<string>) => {
  if (!isoString) return null;
  const { hour, minute, period } = parseIsoToDateTimeValues(
    isoString,
    'TIME_ONLY'
  );

  return `${hour}:${minute} ${period}`;
};

export const utcOffsetTimeZoneIdMap: { timeZoneId: number; offset: number }[] =
  [
    { timeZoneId: 1, offset: 210 }, // Newfoundland
    { timeZoneId: 2, offset: 240 }, // Atlantic
    { timeZoneId: 3, offset: 300 }, // Eastern
    { timeZoneId: 4, offset: 360 }, // Central
    { timeZoneId: 5, offset: 420 }, // Mountain
    { timeZoneId: 6, offset: 480 }, // Pacific
    { timeZoneId: 7, offset: 540 }, // Alaskan
  ];

export function getClientTimeZoneId() {
  // get the timezone offset for the current browser
  // in january to avoid daylight savings time
  const clientUTCOffset = new Date(
    new Date().getFullYear(),
    0,
    1
  ).getTimezoneOffset();
  return (
    utcOffsetTimeZoneIdMap.find((tz) => tz.offset === clientUTCOffset)
      ?.timeZoneId ?? null
  );
}

export const isISOValid = (key: DateSegment, value: string) => {
  const { maxLength, minValue, maxValue } = inputValidationRules[key];
  const valueNumber = Number(value);

  return (
    value.length === maxLength &&
    valueNumber >= minValue &&
    valueNumber <= maxValue
  );
};

export function validateIso(
  isoStr: Nullish<string>,
  valuesToCheck: DateSegment[]
): DateSegment[] {
  if (!isoStr) return [];

  const parsedDateTimeValues = parseIsoToDateTimeValuesWithMilitaryHour(isoStr);

  const errors = valuesToCheck.filter((key) => {
    if (key === 'hour') {
      // Validate the parsed military hour, not civilian

      return !isISOValid(key, parsedDateTimeValues.militaryHour);
    }

    return (
      !isISOValid(key, parsedDateTimeValues[key]) ||
      (key === 'day' && !validateDay(parsedDateTimeValues, isISOValid))
    );
  });

  return errors;
}

// gets the difference between two dates in minutes, hours, or days
export function getTimeDifference(
  startTime: string,
  endTime: string,
  unit: 'minutes' | 'hours' | 'days' = 'minutes'
) {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const difference = endDate.getTime() - startDate.getTime();

  const conversionFactor = {
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
  };

  return difference / conversionFactor[unit];
}

// gets the percentage of time elapsed between two dates
export function getPercentageComplete(startTime: string, endTime: string) {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const now = new Date();

  if (startDate === endDate) return 100;

  if (endDate < startDate) return 0;

  const totalDifference = endDate.getTime() - startDate.getTime();
  const elapsedDifference = now.getTime() - startDate.getTime();

  const percentComplete = Math.round(
    (elapsedDifference / totalDifference) * 100
  );

  return Math.min(percentComplete, 100);
}

export function firstDateIsBeforeSecondDate(
  firstDate: string | null,
  secondDate: string | null
) {
  return dayjs(secondDate).isBefore(firstDate);
}
