import { createBrowserHistory } from 'history';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const history = createBrowserHistory();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
    disableTelemetry: process.env.REACT_APP_ANALYTICS_DISABLED === 'true',
    // Errors/exceptions are collected in the ErrorBoundary component.
    disableExceptionTracking: true,
    instrumentationKey: process.env.REACT_APP_ANALYTICS_KEY,
    maxBatchInterval: 500,
  },
});
appInsights.loadAppInsights();

export default appInsights;
