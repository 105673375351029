import { DeepOmit, Nullish } from './typeUtils';

/**
 * Checks to see if a value is not nullish.
 * @param value - any type of value
 * @returns {boolean} True if the value is not nullish, false if it is nullish.
 *
 * @description
 * This function is mainly used to check for defined number values
 * since a regular truthy check will fail for 0. An example use case would be
 * conditionally rendering a field based on the presence of its value, while
 * still allowing 0 to be displayed.
 */
export function hasValue<TValue>(value: Nullish<TValue>): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * Retrieves the First Property of an Object
 *
 * @description
 * This function is mainly used to retrieve the response value from a graphql
 * query or mutation with a response object that has a single property.
 * We have a fair amount of generic code that works with graphql operations
 * regardless of the data in the response. For example, a function may work
 * with a query that returns { patterns: [] } and a query that returns
 * { shipments: [] }, so this utility could be used to target the value from
 * the property regardless of the property key.
 *
 * @template T - The type of the property values in the object.
 *
 * @param data Object with a string as its key's value
 * @returns Returns the first property of an object
 */
export const getFirstProperty = <T>(data: Nullish<Record<string, T>>) =>
  data?.[Object.keys(data)[0]];

/**
 * Return Display Text based on the truthfulness of a Given Value
 * @param bool - value to be checked for truthfulness
 * @returns {boolean} Returns 'Yes' or 'No' depending on value truthfulness
 */
export function getBoolDisplayText(bool: Nullish<boolean>) {
  return bool ? 'Yes' : 'No';
}

/**
 *
 * @returns Given a value and its unit, returns the text to display. If the value is null,
 * undefined, or an empty string, an empty string will be returned. Otherwise, returns the
 * value appended with the unit.
 */
export const getValueWithUnitDisplayText = (
  value: Nullish<string | number>,
  unit: string
) => (!value && value !== 0 ? null : `${value} ${unit}`);

export const boolOptions = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

/**
 * Reformats a string to be a kebab-lowercased format (IE: this-is-a-string)
 * @param text text to reformat
 * @returns {boolean} Returns the input text as lowercased-kebab style
 *
 * @description
 * This function is used to format ids and data-testids for for runtime values
 * in a consistent way.
 *
 * @todo
 * Correct spelling of "kebab".
 */
export function textToKabobCase(text = '') {
  return text.toLowerCase().split(' ').join('-');
}

/**
 * Convert a City and State into a Single String (IE: 'foo, bar')
 * @param city - city text
 * @param state - state text
 * @returns {boolean} Returns the city and state in a single string or one of the other if one of them is not provided
 */
export function formatCityState(city: Nullish<string>, state: Nullish<string>) {
  return city && state ? `${city}, ${state}` : city || state;
}

/**
 * `delimiterRegex`
 * @description used to split pasted lists with specific delimiters that we support
 */
export const delimiterRegex = /[,;*](?:\r\n|\r|\n)|(?:\r\n|\r|\n)|[,;*]/;

/**
 * Recursively removes the provided keys from an object.
 *
 * @description
 * This function is useful for removing properties on client-side objects such as
 * form state before submitting to the backend. For example, in our form state,
 * we require _ids on all array objects to use as React keys, but the _ids do not
 * exist on our input types in the backend, so we strip them off before submitting.
 *
 * @note
 * This function is cheating in terms of type safety by using the
 * JSON.parse(JSON.stringify()) trick to omit the object keys because JSON.parse
 * returns any and we do nothing to verify that the returned structure correctly
 * matches the DeepOmit return type. The test coverage in the related test file is
 * essential to ensuring this function works as expected. Also this function used
 * to take an array of keys which was more convenient as we often need to remove
 * multiple keys at once, but this DeepOmit utility type is not compatible with an
 * array of strings and must be provided string literals. This is why we support
 * an optional second key with the conditional nested return type.
 * The implementation and DeepOmit utility were taken from this SO post:
 * https://stackoverflow.com/questions/55539387/deep-omit-with-typescript
 *
 * @template T - The type of the object to remove the keys from, can be either an
 * array or a plain object.
 * @template K - The key to remove from the provided type. Note, this type extending
 * string and not keyof T is necessary so this function is works with removing a
 * key from a nested type that may not be on a parent type (for example if _id is on
 * an object in a nested child array but not on the parent object).
 * @template K2 - An optional second key.
 *
 * @param item - An object from which the provided keys will be removed.
 * @param keyToRemove - The key to be removed.
 * @param keyToRemove2 - An optional second key to be removed.
 * @return - Returns the object with the keys removed.
 */
export function removeObjectKeys<
  T,
  K extends string,
  K2 extends string | undefined = undefined
>(
  item: T,
  keyToRemove: K,
  keyToRemove2?: K2
): K2 extends string ? DeepOmit<DeepOmit<T, K>, K2> : DeepOmit<T, K> {
  return JSON.parse(JSON.stringify(item), (key: string, value: unknown) =>
    key === keyToRemove || key === keyToRemove2 ? undefined : value
  );
}

/**
 * @return Returns the font size of the document's root element in pixels.
 */
export function getRootPixelFontSize() {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
}

/**
 * Find all available form value options for desired form input type.
 *
 * @description
 * This function is used to prevent duplicate values from being selected
 * across array fields where the selected values must be unique.
 *
 * @param queriedOptions - queried data options for input type
 * @param currentSelectedInput - current selected input to filter from
 * @param selectedInputList - current list of selected inputs to filter from
 * @param refId - referential id name to filter from
 * @returns filtered list of given input type
 */
export const getAvailableOptions = <
  TQueriedInputOption extends { id: number },
  TInputType extends Record<string, unknown>
>(
  queriedOptions: Nullish<TQueriedInputOption[]>,
  currentSelectedInput: Nullish<TInputType>,
  selectedInputList: TInputType[],
  refId: keyof TInputType
) =>
  queriedOptions?.filter((option) => {
    // Include the option if it is selected by the current input
    const isOwnId =
      currentSelectedInput && option.id === currentSelectedInput[refId];

    // Exclude the options already selected by other inputs
    const idIsAvailable = !selectedInputList.find(
      (input) => input && option.id === input[refId]
    );

    return isOwnId || idIsAvailable;
  });

/**
 * Returns the sum of a specified key's values across an array of objects.
 * The type of the key's value must be assignable to number | string | null, and the key must
 * be present in all of the objects in the array. Keys ending in 'id' are not
 * allowed to be used.
 */
export function getFieldSumFromObjectArray<TKey extends string>(
  field: Lowercase<TKey> extends `${string}id` ? never : TKey,
  items: Nullish<(Record<TKey, number | null> | null)[]>
) {
  if (!items) return null;
  const total = items.reduce<number | null>((total, item) => {
    if (!item) return total;
    const fieldVal = item[field];
    if (total === null) {
      return fieldVal;
    }
    return Number(fieldVal) + total;
  }, null);
  return total;
}

const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

/**
 * Converts a number to its corresponding ordinal (1st, 2nd, 3rd)
 *
 * Read about ordinals here: {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules}
 */
export const numToOrdinal = (n: number): string => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

/**
 * A utility for downloading a browser native File object.
 *
 * @param file - Native browser File {@link https://developer.mozilla.org/en-US/docs/Web/API/File}
 * @param suggestedName - suggested name for file when user is prompted to download.
 */
export const downloadFileInBrowser = async (
  file: File,
  suggestedName?: string
) => {
  // create a new handle

  const newHandle = await window.showSaveFilePicker({
    suggestedName: suggestedName ?? file.name,
  });

  // create a FileSystemWritableFileStream to write to
  const writableStream = await newHandle.createWritable();

  // write our file
  await writableStream.write(file);

  // close the file and write the contents to disk.
  await writableStream.close();
};

/**
 * A type guard for Record<string, unknown>.
 */
export function isRecord(x: unknown): x is Record<string, unknown> {
  return !!x && typeof x === 'object' && !Array.isArray(x);
}

/**
 * This flag is used for disabling certain features in our production
 * environment.
 *
 * @description
 * Sometimes we hide features from the production environment so clients
 * aren't made aware of them until they have been thoroughly tested in
 * the beta environment.
 *
 * @note
 * This is not to be confused with process.env.NODE_ENV which communicates
 * the build type of the app. For example, our dev, beta, and production
 * environments all have process.env.NODE_ENV === 'production' despite only
 * one being our true production environment that clients use. This is because
 * they are all "production" builds as far as create-react-app is concerned.
 */
export const isProductionEnvironment =
  process.env.REACT_APP_HOST === 'https://railcommand.rsinext.com';

/**
 * This flag is used for disabling/enabling certain features depending on if
 * we're developing locally or in any of our deployed environments that use
 * a production build.
 *
 * @see {isProductionEnvironment} above for more details.
 */
export const isProductionBuild = process.env.NODE_ENV === 'production';

/**
 * Given a length in inches, returns the number of feet in the length.
 */
export const getFeetFromTotalInches = (totalInches: string | number | null) => {
  if (totalInches === null || totalInches === '') return '';
  const valueAsNumber = Number(totalInches);
  if (isNaN(valueAsNumber)) return '';
  const feet = Math.floor(valueAsNumber / 12);
  return String(feet);
};

/**
 * Given a length in inches, returns the number of inches left in the length
 * after subtracting out the maximum number of feet.
 */
export const getInchesFromTotalInches = (
  totalInches: string | number | null
) => {
  if (totalInches === null || totalInches === '') return '';
  const valueAsNumber = Number(totalInches);
  if (isNaN(valueAsNumber)) return '';
  const inches = valueAsNumber % 12;
  return String(inches);
};

/**
 *
 * Converts a length in inches into feet/inches format. (__ ft __ in)
 */
export const getFormattedFeetInches = (totalInches: string | number | null) => {
  if (totalInches === null) return '';

  return `${getFeetFromTotalInches(totalInches)} ft ${getInchesFromTotalInches(
    totalInches
  )} in`;
};

export const truncateString = (str: string | null, length: number) => {
  if (!str) return str;
  return str.length > length ? `${str.slice(0, length)}...` : str;
};

export function getMeasurementUnitAbbrev(
  units: { id: number; abbreviation: string | null }[] | null | undefined,
  unitId: number | null
) {
  if (!unitId) return null;
  return units?.find((unit) => unit.id === unitId)?.abbreviation ?? null;
}
