import { CurrencyEnum } from 'schema';

import { Nullish } from './typeUtils';

/**
 * Formats Currency with Provided Locale String
 * @param number - amount of currency
 * @param currencyEnum - locale string format {@link CurrencyEnum}
 * @returns Returns the provided number formatted as the given currency locale format.
 */
export function formatCurrency(
  number: Nullish<number>,
  currencyEnum: CurrencyEnum = 'USD'
): string | undefined {
  return number?.toLocaleString(navigator.language, {
    style: 'currency',
    currency: currencyEnum,
    minimumFractionDigits: 2,
    maximumFractionDigits: 20,
  });
}
