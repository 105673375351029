import { ReactComponent as RsiLogo } from 'images/rail-command-logo.svg';

import styles from './LoadingSpinner.module.css';

interface LoadingSpinnerProps {
  isOverlay?: boolean;
  isFullScreen?: boolean;
  isTableRow?: boolean;
  style?: React.CSSProperties;
  className?: string;
  testid?: string;
}

export default function LoadingSpinner({
  isOverlay,
  isFullScreen,
  isTableRow,
  style,
  className,
  testid,
}: LoadingSpinnerProps) {
  const wrapperClasses = [styles['spinner-wrapper']];
  if (isOverlay) wrapperClasses.push(styles['overlay']);
  if (isFullScreen) wrapperClasses.push(styles['full-screen']);
  if (className) wrapperClasses.push(className);

  const spinnerClasses = [styles['spinner']];
  if (isTableRow) spinnerClasses.push(styles['table-row']);

  return (
    <div
      className={wrapperClasses.join(' ')}
      style={style}
      data-testid={testid}
    >
      {isFullScreen ? <RsiLogo className={styles['logo']} /> : null}
      <div className={spinnerClasses.join(' ')}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
