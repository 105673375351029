import { Component } from 'react';
import StackTrace from 'stacktrace-js';

import appInsights from 'configs/analyticsConfig';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback: JSX.Element;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false };

  async componentDidCatch(error: Error) {
    this.setState({ hasError: true });

    const pinpointedError = await StackTrace.fromError(error).catch(
      (err) => err
    );

    appInsights.trackException({
      exception: error,
      properties: {
        url: window.location.href,
        stackTrace: pinpointedError,
      },
    });
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return fallback;
    }
    return children;
  }
}

export default ErrorBoundary;
