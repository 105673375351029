import { Maybe, ShipmentEquipmentSeal, WaybillEquipmentSeal } from 'schema';

/**
 * `unformattedEquipmentCodeRegex`
 * @description Used to capture the equipmentInitial and equipmentNumber of a raw
 * equipmentCode from user input that may or may not already be formatted
 */
export const unformattedEquipmentCodeRegex =
  /^([a-zA-Z]{2,4})(?: *)([0-9]{1,6})/;

/**
 * Used to validate an equipment initial that may or may not already be formatted
 */
export const unformattedEquipmentInitialRegex = /^[A-Za-z]{2,4}\s*$/;

/** Matches a valid formatted equipment code. This is the same equipment code
 * validation that is performed in the backend. Note that this does not currently check for length
 */
export const formattedEquipmentCodeRegex =
  /([A-Z]{2}[ ]{2}|[A-Z]{3}[ ]{1}|[A-Z]{4})(?!0{6})[0-9]{6}/;

/**
 * Matches a valid formatted equipment initial
 */
export const formattedEquipmentInitialRegex =
  /^([A-Z]{2}[ ]{2}|[A-Z]{3}[ ]{1}|[A-Z]{4})$/;

/**
 *  Matches a valid formatted equipment initial without padding
 */
export const formattedEquipmentInitialNoPaddingRegex = /^[A-Z]{2,4}$/;

/**
 * Matches a valid formatted equipment number
 */
export const formattedEquipmentNumberRegex = /^((?!0{6})[0-9]{6})$/;

/**
 * Formats the Equipment Initial part of an Equipment Code
 * @param value - The raw Equipment Initial
 * @return The formatted Equipment Initial
 */
export const formatEquipmentInitial = (value: string) =>
  value === '' ? '' : value.trim().toUpperCase().padEnd(4, ' ');

/**
 * Formats the Equipment Number part of an Equipment Code
 * @param value - The raw Equipment Number
 * @returns The formatted Equipment Number
 */
export const formatEquipmentNumber = (value: string): string =>
  value === '' ? '' : value.trim().padStart(6, '0');

/**
 * Formats an Equipment Code
 * @param value - The raw Equipment Code
 * @returns The formatted Equipment Code
 *
 * @description
 * An Equipment Code is effectively the license plate for
 * a rail car. They are formatted as XXXX000000 where
 * XXXX is the Equipment Initial and the 000000 is the
 * Equipment Number.
 *
 * The Equipment Initial must be 2-4 alphabetical characters
 * with trailing padded spaces totaling 4 characters.
 * @see {@link formatEquipmentInitial}
 *
 * The the Equipment Number must be a number from 1 to
 * 999999 with leading padded 0s totaling 6 characters.
 * @see {@link formatEquipmentInitial}
 *
 * Commonly users will type a shorthand version of an
 * equipment code, such as "ab123", and this function
 * is used to format that shorthand into the valid Equipment
 * Code of "AB  000123".
 */
export const formatEquipmentCode = (value: string) => {
  const matchArray = value.trim().match(unformattedEquipmentCodeRegex);
  if (!matchArray) return value;

  const equipmentInitial = matchArray[1] || '';
  const equipmentNumber = matchArray[2] || '';
  return (
    formatEquipmentInitial(equipmentInitial) +
    formatEquipmentNumber(equipmentNumber)
  );
};

/**
 * Extracts the equipment number and equipment initial from a valid, formatted equipment code
 */
export function extractEquipmentInitialAndNumberFromEquipmentCode(
  equipmentCode: string
) {
  return {
    equipmentInitial: equipmentCode.substring(0, 4),
    equipmentNumber: equipmentCode.substring(4, 10),
  };
}

/**
 * Formats and Delimits an Array of Shipment or Waybill Equipment Seals
 * @param equipmentSeals - array of {@link ShipmentEquipmentSeal} or {@link WaybillEquipmentSeal}
 * @returns The formatted concatenation of the equipment Seals.
 *
 * @description
 * This function formats an array of equipment seals by concatenating each
 * seal's sealNumber into a single string and delimiting each with
 * an asterisk (*). We do this because we store equipment seals in a table
 * in the database but users expect to see them formatted like this in the UI.
 */
export const formatEquipmentSeals = (
  equipmentSeals: Maybe<
    Pick<ShipmentEquipmentSeal & WaybillEquipmentSeal, 'sealNumber'>
  >[]
) =>
  equipmentSeals
    .map((equipmentSeal) => equipmentSeal?.sealNumber ?? '')
    .join('*') ?? '';
