import { FaExclamationTriangle } from 'react-icons/fa';

import { ReactComponent as RsiLogo } from 'images/rail-command-logo.svg';

import styles from './ErrorAlert.module.css';

export interface ErrorAlertProps {
  isFullScreen?: boolean;
  icon: JSX.Element;
  title: string;
  message: string;
  children?: React.ReactNode;
}

export default function ErrorAlert({
  isFullScreen,
  icon,
  title,
  message,
  children,
}: ErrorAlertProps) {
  return (
    <div
      className={`${styles['alert-container']} ${
        isFullScreen ? styles['height-100vh'] : styles['width-height-100']
      }`}
    >
      {isFullScreen ? <RsiLogo className={styles['logo']} /> : null}
      <div className={styles['alert-wrapper']}>
        <div className="alert alert-danger">
          <h1 className={styles['alert-header']}>
            {icon}
            {title}
          </h1>
          <p>{message}</p>
        </div>
        {children}
      </div>
    </div>
  );
}
export interface GenericErrorProps {
  isFullScreen?: boolean;
}
export function GenericError({ isFullScreen }: GenericErrorProps) {
  return (
    <ErrorAlert
      title="Something went wrong"
      icon={<FaExclamationTriangle />}
      message="Please contact us. In the meantime, try refreshing the page."
      isFullScreen={isFullScreen}
    />
  );
}
