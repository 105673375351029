import Bowser from 'bowser';
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

import ErrorAlert from './ErrorAlert/ErrorAlert';

export interface DetectBrowserProps {
  children: JSX.Element;
}
export default function DetectBrowser({ children }: DetectBrowserProps) {
  const browser = Bowser.getParser(window.navigator.userAgent);

  const isValidBrowser = browser.satisfies({
    edge: '>=79',
    chrome: '>=69',
    firefox: '>=62',
    safari: '>=12',
  });

  if (!isValidBrowser) {
    return (
      <ErrorAlert
        title="This browser is unsupported"
        icon={<FaExclamationTriangle />}
        message="Please upgrade your browser to the latest version. For the best experience, use the latest version of Chrome, Edge, Firefox, or Safari."
        isFullScreen
      />
    );
  }
  return children;
}
