import { PartyPartyTypeInput, PartyTypeEnum } from 'schema';

import {
  RULE_11_PAYMENT_METHOD_ID,
  RULE_11_PARTY_TYPE_ID,
  IN_CARE_OF_PARTY_TYPE_ID,
  PICKUP_PARTY_TYPE_ID,
  CONSIGNEE_PARTY_TYPE_ID,
  SHIPPER_PARTY_TYPE_ID,
} from './businessRuleIds';

/**
 * Function for finding if the Given Party Input conforms to the Designated Business Rule
 * @param partyInput - Party Input {@link PartyPartyTypeInput}
 * @returns true if the partyType conforms to the business designated business rule
 *
 * @description
 * The following party type utilities are used to detect certain party types that are
 * relevant to key business rules. They all take an argument of type {@link PartyPartyTypeInput}
 * because this is the minimum required interface for this function, but they will commonly
 * be used with other interfaces that extend this common interface, such as PatternPartyInput,
 * ShipmentPartyInput, or DiversionPartyInput.
 *
 * They are commonly used to filter arrays of partyInputs.
 */
export function isShipperPartyType(partyInput: PartyPartyTypeInput) {
  return partyInput.partyTypeId === SHIPPER_PARTY_TYPE_ID;
}

/** @see {@link isShipperPartyType} */
export function isConsigneePartyType(partyInput: PartyPartyTypeInput) {
  return partyInput.partyTypeId === CONSIGNEE_PARTY_TYPE_ID;
}

/** @see {@link isShipperPartyType} */
export function isInCareOfPartyType(partyInput: PartyPartyTypeInput) {
  return partyInput.partyTypeId === IN_CARE_OF_PARTY_TYPE_ID;
}

/** @see {@link isShipperPartyType} */
export function isPickupPartyType(partyInput: PartyPartyTypeInput) {
  return partyInput.partyTypeId === PICKUP_PARTY_TYPE_ID;
}

/** @see {@link isShipperPartyType} */
export function isRule11PartyType(partyInput: PartyPartyTypeInput) {
  return partyInput.partyTypeId === RULE_11_PARTY_TYPE_ID;
}

/**
 * Function for finding if the Given Payment Method Id is the Rule 11 payment method
 * @param paymentMethodId - number
 * @returns true if the payment method id is rule 11
 */
export function isRule11PaymentMethod(paymentMethodId: number | null) {
  return paymentMethodId === RULE_11_PAYMENT_METHOD_ID;
}

/**
 * Function for finding if the Given Party Input is optional.
 * @param partyInput - Party Input {@link PartyPartyTypeInput}
 * @returns true if the party type is optional
 *
 * @description
 * A shipment always requires a shipper and a consignee party, and at least 1
 * rule 11 party if the payment method is rule 11. This function is commonly
 * used to filter a list of partyInputs to include all other party types.
 */
export function isOptionalPartyType(partyInput: PartyPartyTypeInput) {
  return (
    !isShipperPartyType(partyInput) &&
    !isConsigneePartyType(partyInput) &&
    !isRule11PartyType(partyInput)
  );
}

export type PartyType = {
  id: number;
  name: string;
  enumeration: PartyTypeEnum;
};

/**
 * Filter a List of Party Type Options
 *
 * @description
 * This function is used to filter a party type dropdown input to only include
 * the valid party types for the field based on specific business rules.
 */
export const getPartyTypeOptions = (
  currentPartyInput: PartyPartyTypeInput,
  partyTypes: PartyType[],
  paymentMethodId: number | null,
  partyInputs: PartyPartyTypeInput[] = []
) => {
  const filteredPartyTypes = isRule11PaymentMethod(paymentMethodId)
    ? partyTypes.filter(
        (partyType) => partyType.enumeration !== 'PAYER_FREIGHT'
      )
    : partyTypes;

  const shouldRemoveInCareOf =
    !isInCareOfPartyType(currentPartyInput) &&
    partyInputs.find(isInCareOfPartyType);

  const shouldRemovePickup =
    !isPickupPartyType(currentPartyInput) &&
    partyInputs.find(isPickupPartyType);

  if (shouldRemovePickup && shouldRemoveInCareOf) {
    return filteredPartyTypes.filter(
      (partyType) =>
        ![PICKUP_PARTY_TYPE_ID, IN_CARE_OF_PARTY_TYPE_ID].includes(partyType.id)
    );
  }

  if (shouldRemovePickup)
    return filteredPartyTypes.filter(
      (partyType) => partyType.id !== PICKUP_PARTY_TYPE_ID
    );

  if (shouldRemoveInCareOf)
    return filteredPartyTypes.filter(
      (partyType) => partyType.id !== IN_CARE_OF_PARTY_TYPE_ID
    );

  return filteredPartyTypes;
};
